import { useEffect, useState } from 'react';
import { AdaptiveTypography } from '../../components/base';
import style from './scheduleCall.module.scss';
import { getAllTherapists, getAvailableSlotForTherapist } from '../../services/callSchedule.service';
import Button from '../../components/base/button';
import { useNavigate } from 'react-router-dom';
import PriceText from '../../components/common/price-text';
import mixpanel from 'mixpanel-browser';

const TherapistSelectionPage = () => {

    useEffect(() => {
        try {
            mixpanel.track('Therapist Selection View', {
                isWebFlow: true,
            });
        } catch (error) {
            //
        }
    }
    )

    const [therapists, setTherapists] = useState([])

    const getTherapists = async () => {
        let therapists = await getAllTherapists()
        let avialbleTherapists = []
        for (let therapist of therapists) {
            const slots:Array<any> = await getAvailableSlotForTherapist((therapist as any).id!);
            if(slots?.length>0){
                avialbleTherapists.push(therapist)
            }
        }
        avialbleTherapists.sort((t1,t2)=>t1.name.localeCompare(t2.name))
        setTherapists(avialbleTherapists as any)
    }

    const navigate = useNavigate()

    useEffect(
        ()=>{
            getTherapists()
        },[]
    )

    return <div className={style.scheduleCallContainer}>
        <AdaptiveTypography variant='h1'>Our Therapists</AdaptiveTypography>

        <div style={{height:'20px'}} />

        <div className={style.therapistCards}>
        {
            therapists.map(
                (therapist:any) => {
                    return <div key={therapist.id} className={style.therapistCard}>
                            <img className={style.therapistImage} src={therapist.picture} />
                            <div className={style.therapistInfoBox}>
                                <AdaptiveTypography variant='h4'> {therapist.name} </AdaptiveTypography>
                                <AdaptiveTypography variant='body'> {therapist.education} </AdaptiveTypography>
                                <span style={{display:'flex', gap:'5px', alignItems:'center'}}> <AdaptiveTypography variant='h4'> <span className={style.pricingspan} >₹<PriceText priceKey="offlineIntialCall" /> for 60 mins </span> </AdaptiveTypography> </span>
                                <div style={{flexGrow:1, minHeight:'8px'}} />

                                <div className={style.therapistButtonContainer}>
                                    <Button onClick={()=>{navigate(`/offline/callbook/${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Book</Button>
                                    <Button variant='outlined' onClick={()=>{navigate(`/therapist?id=${therapist.id}`,{relative:'path'})}} style={{width:'fit-content'}}>Profile</Button>

                                </div>
                            </div>
                            
                        </div>
                }
            )
        }
        </div>

        <div className={style.spacer} />

    </div>

}

export default TherapistSelectionPage